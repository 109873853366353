const storageKey = '_10pines2024_conf_storage';
const storage = window.localStorage;

class PersonalSchedule {
  constructor(timespans) {
    const storedData = storage.getItem(storageKey);

    if(storedData) {
      const parsedStoredData = JSON.parse(storedData);

      if(parsedStoredData.length !== timespans.length) {
        this._resetTalksSelections(timespans);
      } else {
        this.selectedTalks = JSON.parse(storedData);
      }
    } else {
      this._resetTalksSelections(timespans);
    }
  };

  _resetTalksSelections(timespans) {
    this.selectedTalks = timespans.map((timespan) => ({ timespanId: timespan.time, talkIndex: 0 }));
    this.save();
  };

  selectTalk({ timespanId, talkIndex }) {
    this.selectedTalk(timespanId).talkIndex = talkIndex;
    this.save();
  };

  talkForTimespan({ timespanId }) {
    return this.selectedTalk(timespanId).talkIndex;
  };

  selectedTalk(timespanId) {
    return this.selectedTalks.find((selectedTalk) => selectedTalk.timespanId === timespanId);
  };

  save() {
    storage.setItem(storageKey, JSON.stringify(this.selectedTalks));
  }
}

export default PersonalSchedule;
