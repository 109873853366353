import React from 'react';
import Slider from 'react-slick';
import Talk from './Talk';
import './Timespan.css';

function Timespan({userSchedule, time, talks}) {
  const sliderSettings = {
    centerMode: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 50,
    slidesToShow: 3,
    slidesToScroll: 3,
    focusOnSelect: true,
    initialSlide: userSchedule.talkForTimespan({ timespanId: time }),
    adaptiveHeight: true,
    className: 'sarlanga',
    afterChange: (index) => userSchedule.selectTalk({ timespanId: time, talkIndex: index }),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const renderTalks = () => talks.map((talk, i) => <Talk key={i} title={talk.title} author={talk.author} room={talk.room} details={talk.details} feedbackLink={talk.feedbackLink} meetLink={talk.meetLink}/>);

  return (
    <div>
      <div className="divider-container">
        <div className="divider divider-left"></div>
        <span className="time">{time}</span>
      </div>

      <Slider {...sliderSettings}>
        { renderTalks() }
      </Slider>
    </div>
  );
}

export default Timespan;
