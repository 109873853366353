import {redRoom, orangeRoom, yellowRoom, greenRoom, northRoom, capacitationRoom, openspacitoRoom} from './Rooms';

const buildTalk = (title, author, room, details, feedbackLink, meetLink) => {
  return {
    title,
    author,
    room,
    details,
    feedbackLink,
    meetLink,
  }
};

const schedule = [
  {
    time: '10.00 AM',
    description: 'Charla de apertura 🔗',
    break: true,
    link: capacitationRoom.meetLink,
  },
  {
    time: '10.20 AM',
    talks: [
      buildTalk('Backlog priorizado y orientado en el usuario', 'Gaby', redRoom, 'Voy a contar sobre un par de herramientas que vi durante el curso que son interesantes para hacer con el cliente. El objetivo de las mismas es encontrar puntos de dolor en la app, generar ideas, y priorizar necesidades.', 'https://forms.gle/Ldr7r1ruzHjws1yd9'),
      buildTalk('Los algoritmos de la Astrología', 'Lucas W.', yellowRoom, 'Hace poco me crucé con una pregunta en Quora (una página tipo Yahoo Respuestas) que me volvió loco: "Puede ser que las páginas de Tarot usen algoritmos, y por lo tanto los resultados de las tiradas no sean precisos?"\n' +
          '\n' +
          'Me gustaría ahondar en las webs que ofrecen tiradas de Tarot online y aplicaciones de astrología, ver cómo funcionan por detrás, y cómo las personas construimos esta inevitable relación entre la coincidencia y el destino', 'https://forms.gle/pu4tbmujUPK1YWug8'),
      buildTalk('Microfrontends al vapor (con Flutter)', 'Lowy y Xime', orangeRoom, 'Microfrontends? En esta epoca del año? A esta hora del día? En esta parte del país? Y en una app mobile? - Si\n' +
          '\n' +
          'Si querés ver como, por qué, y algunos detalles de implementación, pasate. ', 'https://forms.gle/hKxx4yj1ZT4f6C357'),
      buildTalk('Linters y su impacto en la Developer Experience', 'Nahue G.', greenRoom, 'Bondades y desafíos del análisis estático de código (lint). Tips prácticos para que sea una herramienta a tu favor y no algo que te hinche las guindas. Cómo esto puede afectar positiva o negativamente a la Developer Experience (DX).', 'https://forms.gle/XWt2oN9QyksZ5Nd26'),
      buildTalk('Basta de estimaciones', 'Joa', northRoom, 'Los story points fueron un error. No lo digo yo, lo dice su creador. La charla parte de la premisa de que las estimaciones traen más problemas que beneficios; vamos a ver qué alternativas surgieron para ver si podemos sencillamente no estimar y ser felices.', 'https://forms.gle/KgSgFxuCZdZev3Xq8'),
    ]
  },
  {
    time: '11.00 AM',
    description: 'Break',
    break: true
  },
  {
    time: '11.10 AM',
    talks: [
      buildTalk('Limpiando auras con paréntesis y polimorfismo', 'Lauta', yellowRoom, 'Les noto con los chacras un poco desalineados. En esta charla vamos a ver un poco de Clojure, ver qué herramientas de polimorfismo nos da y como podemos implementar un sistema que nos limpie las malas energías', 'https://forms.gle/EoSwMcTT932ZxDcM6'),
      buildTalk('¿Puede Gemini codear mi app mobile con Flutter?', 'Leo', orangeRoom, 'Me impersono en un CEO rata que no quiere/puede pagar devs y le doy a Gemini un png con la UI de mi app para que la codee en Flutter ¿hasta dónde llegará?', 'https://forms.gle/dY62jxr8SeAc11n97'),
      buildTalk('¿Pueden los grafos contarme una historia?', 'Daro', redRoom, 'Hay distintas formas de contar una historia, y los juegos soulslike las cuentan de una forma bastante apocrifa a traves de sus items, en esta charla vamos a analizar si podemos usar grafos tratar de entender la historia de uno de los juegos.', 'https://forms.gle/emyXkEVYE4fhsp6bA'),
      buildTalk('Escapando de un framework zombie', 'Tom', greenRoom, 'Voy a contar mi experiencia migrando una app de 10+ años de EmberJS a React. Voy a hablar sobre por qué se decidió hacer la migración, cómo se llevó a cabo, los desafíos esperados e inesperados que hubieron y cuántas veces lloré en el proceso.', 'https://forms.gle/s9AQf8Vt5Ftfe7uc9'),
      buildTalk('Claves de un equipo de producto outsourceado', 'Fede Z', northRoom, 'Los equipos de producto modernos participan en todas las etapas de desarrollo, son multidisciplinarios con un liderazgo colaborativo de negocios, usabilidad y técnico y aprenden eficientemente a través de ciclos cortos donde se prioriza el outcome sobre el output. ¿Podríamos tener un equipo de esta índole cuando la pata técnica está outsourceada? En 10Pines lo venimos haciendo hace mucho tiempo. Estos son los aspectos claves para que funcione:\n' +
          '\n' +
          'Que la relación comercial habilite la colaboración\n' +
          'Que exista total transparencia\n' +
          'Devs capacitados y motivados\n' +
          '\n' +
          'En esta charla discutiremos los aspectos claves de un equipo de producto moderno y trataremos de dilucidar si es posible alcanzarlo cuando los desarrolladores no pertenecen a la empresa. ', 'https://forms.gle/4dqtoukdJFGn4NKZA'),
    ]
  },
  {
    time: '11.50 AM',
    description: 'Break',
    break: true
  },
  {
    time: '12.00 AM',
    talks: [
      buildTalk('Morfología de TÚ identidad', 'Juli R. y Titi', yellowRoom, 'Vamos a ver cómo se organizan los datos en tu DNI y para qué sirven, desde el código del frente hasta los números que ves en el dorso. También vamos a charlar sobre cómo esos datos se usan para validar tu identidad en el mundo digital.', 'https://forms.gle/Mna9Mrut2MSg4QSx7'),
      buildTalk('Hice una calculadora con IA y te muestro como nos salió', 'Marta', orangeRoom, 'Con J.A.V.I.E.R. les queríamos contar cómo preparamos esta charla. Le propuse hacer una calculadora como ejemplo y le pareció bien, es la primera vez que hacíamos algo juntos así que todavía no nos entendemos tanto. Creo que puede estar bien como ejemplo para acercarle a la pinada el tema de trabajar con una IA de "asistente".', 'https://forms.gle/m4DXgMkM3mxfVdUC6'),
      buildTalk('SFTP y otros protocolos', 'Nahu V.', greenRoom, 'Vos, que te quedaste con ganas de ver más protocolos en redes y comunicaciones, charlita para contarte un poquito de que es SFTP y como darle uso con un poco de typescript.', 'https://forms.gle/Ct5BL173w1UFK2dh9'),
      buildTalk('CSS a través de los tiempos', 'Jess', redRoom, '¿Sabías que hubo tiempos oscuros donde los devs necesitaban hacer botones redondeados pero no existía la propiedad border-radius? ¿Alguna vez intentaste usar vertical-align solo para darte cuenta de que no tenía el efecto que esperabas? ¿Por qué los frontenders odiamos la propiedad float? En esta charla vamos a hacer un repaso de la historia del CSS y contar hitos de su evolución a lo largo de los años para darle luz a algunos vestigios que andan dando vueltas por la internet y poner en valor las joyitas de layouting que tenemos hoy en día.', 'https://forms.gle/BkQJvfboQV6crDG49'),
      buildTalk('Caso de estudio: Smart Open Space', 'Gaby', northRoom, 'Durante el verano termine el curso de ux/ui, el proyecto final fue el caso de estudio de la app Smart Open Space. La idea es mostrarles el resultado final y contarles sobre el proceso.', 'https://forms.gle/dRqZz9sEnDSmM4ok8'),
    ]
  },
  {
    time: '12.40 PM',
    description: 'Almuerzo',
    break: true
  },
  {
    time: '13.40 PM',
    talks: [
      buildTalk('Hicimos un plugin de IntelliJ', 'Lucas W., Ernes y Facu', yellowRoom, 'Hicimos un plugin de IntelliJ y lo mostramos porque esta re piolita.', 'https://forms.gle/PdfuLDTtoatRa7296'),
      buildTalk('Elm: el futuro, ayer', 'λudat', redRoom, 'Vamos a estar explorando una manera "novedosa" de hacer UIs, usando un lenguaje mucho mas estricto que javascript y vamos a ver que conclusiones podemos sacar de eso', 'https://forms.gle/nbmSuCgA8bxwCLb18'),
      buildTalk('En mobile también HAY TABLA', 'Seryo', orangeRoom, 'Qué estrategias podemos utilizar para implementar tablas en mobile.', 'https://forms.gle/XbJWGUkc31t3iVzm9'),
      buildTalk('No seas Socket-e, usá STOMP!', 'Joe', greenRoom, 'Como utilizar websockets en una aplicacion SpringBoot con el protocolo STOMP, que lo hace muchisimo mas facil.', 'https://forms.gle/NGomMsfuyPTCDKHh7'),
      buildTalk('Laputag: Semirretículo en el cielo', 'Joaco', northRoom, 'En esta charla exploraremos cómo los semirretículos nos ayudan a evitar desastres en nuestro código, A través de ejemplos simpáticos y coloridos, veremos cómo una etiqueta puede evitar que sumemos manzanas con naranjas (o peor, precios con y sin IVA). ¡Ven a descubrir cómo el poder de los semirretículos puede hacer tu código más seguro y adorable!', 'https://forms.gle/ZFDN4Rshn5Rzx9sL9'),
    ]
  },
  {
    time: '14.20',
    description: 'Break',
    break: true
  },
  {
    time: '14.30',
    talks: [
      buildTalk('Queries SQL con Aluda', 'Diega', yellowRoom, 'Contar la experiencia de entrenar un modelo de IA para generar queries SQL especificamente para nuestro backoffice.', 'https://forms.gle/M9DDuooQG6aRvvR3A'),
      buildTalk('LiveView Native: el futuro de las Apps', 'Emi', orangeRoom, 'Cuando conociste el framework web Phoenix, no quisiste volver a saber nada de Rails. Después descubriste Phoenix LiveView y saliste corriendo a tirar todas las apps de React que tenias dando vuelta. Ahora llegó lo que te faltaba! LiveView Native, la solución revolucionaria para las apps para dispositivos. Sumate a esta charla para descubrirla y conocer el futuro de las Apps.', 'https://forms.gle/uFTCPtdXKWEpW47B8'),
      buildTalk('Anclame el contenedor', 'Dave', redRoom, 'Las nuevas digievoluciones (not really, but clickbait) de las posiciones absolutas y las consultas de multimedia.', 'https://forms.gle/nirx8yxsVrZefKuh7'),
      buildTalk('Testeando componentes de Relay', 'Luca', greenRoom, 'En el pasado han habido charlas de Relay y como en Lattice lo hemos usado. La idea es hacer un pequeño repaso de los features mas comunes y ver como podemos testear esos componentes', 'https://forms.gle/2gxbJ1bHKjoSBjSc6'),
      buildTalk('Korrutinas 101', 'Ale', northRoom, 'En esta charla voy a mostrar algunos ejemplos prácticos de uso de corrutinas en Kotlin en el contexto de un backend desarrollado con Spring Framework. La idea es explorar desde el uso básico de corrutinas (ejecución concurrente, en paralelo, concurrencia estructurada, etc) hasta el soporte de corrutinas que trae Spring Framework. En el camino veremos algún ejemplo del uso de \'channels\' en Kotlin y, como yapa, la interacción de las corrutinas con Virtual Threads de Java.', 'https://forms.gle/w1RPK2VGYondGo9Y6'),
    ]
  },
  {
    time: '15.10',
    description: 'Break',
    break: true
  },
  {
    time: '15.20',
    talks: [
      buildTalk('Agilidad y Autogestión - Caso de estudio: Show pinero', 'The Champis', openspacitoRoom, 'Esto sería un behind the scenes del show musical de la fiesta de cumple, para visibilizar algunas cositas que fueron pasando en el proceso y son dignas de contar.', 'https://forms.gle/KLkfqM2eDxw3uxCT6'),
    ]
  },
  {
    time: '16.20',
    description: 'Break',
    break: true
  },
  {
    time: '16.30',
    talks: [
      buildTalk('¿Cuáles son mis opciones a la hora de invertir?', 'Igal', yellowRoom, 'Opciones es una palabra ambigua, esta es tu oportunidad para conocer a las Opciones Financieras, Qué son? Cómo funcionan? Para qué sirven? Y cómo podemos aprovecharlas? Si querés conocer las respuestas a esas preguntas, esta es la charla correcta.', 'https://forms.gle/e8m8i1M7ET1nhcnNA'),
      buildTalk('Vini, vidi, mixins', 'Fer', orangeRoom, 'Veremos un ejercicio que se puede resolver aplicando diferentes patrones de diseño, pero como tendremos un lenguaje poderoso eso nos abre el juego a una nueva estrategia: los mixines. ', 'https://forms.gle/iptesGYG3znEgoeV8'),
      buildTalk('¿No podría hacerlo el IDE? Refactors Automatizados', 'Pablo A.', redRoom, 'Les propongo un desafio: refactorizar un codigo de ejemplo con la menor cantidad de intervenciones manuales posibles, favoreciendo el uso de lo que el IDE nos provee. Los refactors automatizados (sobre todo en lenguajes tipados) suelen ser una opcion bastante mas segura que realizar estas mismas tareas de forma manual. La charla esta orientada a ser beginner-friendly, asi que a no sentirse intimidados! La idea es que todos aprendamos algo nuevo!', 'https://forms.gle/t4ZYHcfNvNhfVhPJ9'),
      buildTalk('Guía práctica para que hasta un alien pueda usar tu sitio web', 'Achus', greenRoom, 'Repasemos algunos conceptos y técnicas que pueden ayudarnos a que nuestros sitios lleguen a la mayor cantidad de personas posible.', 'https://forms.gle/jYGubvu68BVz8h2t6'),
      buildTalk('Cachitos de Kotlin', 'Lauta', northRoom, 'Cuando arrancamos un proyecto greenfield en Kotlin nos encontramos con un montón de APIs y funciones de la librería estándar muy interesantes que hacen a una experiencia al desarrollar mucho más fluída. En esta charla vamos a repasar algunos de estos cachitos de Kotlin que más nos llamaron la atención!', 'https://forms.gle/HWqX6VjNyRkPopjG7'),
    ]
  },
  {
    time: '17.10',
    description: 'Break',
    break: true
  },
  {
    time: '17.20',
    talks: [
      buildTalk('¿Qué nos puede enseñar la Fórmula 1 sobre la programación?', 'Charlie', yellowRoom, 'Existen muchos patrones y frameworks para gestionar concurrencia y paralelismo, pero ¿puede un modelo de un solo thread alcanzar el rendimiento necesario? LMAX, una empresa financiera, desarrolló el patrón Disruptor, que permite el intercambio de datos sin bloqueos ni colas. En esta presentación se mostrará cómo LMAX maximizó el rendimiento de su aplicación.', 'https://forms.gle/yXcWrWrDmCRoARA67'),
      buildTalk('Machine Learning para vender', 'Ezequiel Álvarez', orangeRoom, 'Vamos a recorrer algunos algoritmos de IA que se pueden implementar de fácil a difícil, y que a veces podrían mejorar notablemente la calidad de una interacción con un cliente.', ''),
      buildTalk('Spring Scopes', 'Ernes', redRoom, 'Además de crear beans que son singleton, se pueden crear cosas más interesantes scopeandolos a diferentes cosas.', 'https://forms.gle/aFZtGPJ8p4sGeNVv6'),
      buildTalk('Los grandes problemas de GraphQL y sus soluciones', 'Nico R.', greenRoom, 'GraphQL ha ganado popularidad rápidamente gracias a su enfoque particular para desarrollar APIs, que nos permite obtener toda la información que necesitamos en un único request. Sin embargo, esta flexibilidad y poder nos pueden llevar a caer en problemas de seguridad, performance y mantenibilidad si no tenemos cuidado. En esta charla vamos a hablar de cuáles son estos problemas, y de qué formas podemos evitarlos aprovechando tanto herramientas concretas del ecosistema de GraphQL, como buenas prácticas de desarrollo de software en general.', 'https://forms.gle/SKaCqHnxK1AWHQXR8'),
      buildTalk('Soy esto, y es por ahí', 'Maiu', openspacitoRoom, 'Escuchan su voz cuando piensan? Y escuchan música en su cabeza?\n' +
          '\n' +
          'Lo que voy a contar son experiencias de vida, en el marco del show que armamos para la fiesta de 15 de 10pines. Eso implica todo lo que vino ANTES del show pinero, las consecuencias inmediatas POST show, un proceso emocional muy violento, y lo que salió del otro lado.\n' +
          '\n' +
          'Trigger warning: Esta charla la voy a dar llorando y a los gritos. Pero habrán muchos memes para compensar.\n' +
          '\n' +
          'A los escritores de mi vida: gracias por tanto, pero se me calman.\n' +
          '\n' +
          'Nota: a quienes asistan les pido buenas vibras, empatía y contención. Sólo puedo dar esta charla asumiendo que se trata de un espacio seguro.', 'https://forms.gle/xdYQKeQxZEGuZByR8'),
    ]
  },
  {
    time: '18.00',
    description: 'Cierre 🔗',
    break: true,
    link: capacitationRoom.meetLink,
  },
];

export default schedule;
